<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-06-29 16:14:29
 * @LastEditTime: 2022-06-30 10:44:57
-->
<template>
	<div class="studentManHourDetail">
		<common-title :title="'学生工时详情'" />
		<div class="studentManHourDetail-content">
			<div class="common-table">
				<div class="common-table-row">
					<div class="common-table-col">
						<span class="common-table-label">姓名</span>
						<el-input readonly="readonly" v-model="studentManHourDetail.name" placeholder="请输入"
							class="common-table-val" />
					</div>
					<div class="common-table-col">
						<span class="common-table-label">学号</span>
						<el-input readonly="readonly" v-model="studentManHourDetail.studentId" placeholder="请输入"
							class="common-table-val" />
					</div>
				</div>
				<div class="common-table-row">
					<div class="common-table-col">
						<span class="common-table-label">性别</span>
						<span
							class="common-table-text"><span>{{studentManHourDetail.gender == true?"男":"女"}}</span></span>
					</div>
					<div class="common-table-col">
						<span class="common-table-label">总工时</span>
						<el-input readonly="readonly" v-model="studentManHourDetail.workingHours" placeholder="请输入"
							class="common-table-val" />
					</div>
				</div>
			</div>
			<div class="">
				<div class="studentManHourDetail-title">
					工时表
				</div>
				<el-table :data="tableData" border>
					<el-table-column prop="regNum" label="注册号">
						<template slot-scope="scope">
							<span>{{scope.row.volunteer.regNum}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="name" label="姓名">
						<template slot-scope="scope">
							<span>{{scope.row.volunteer.name}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="gender" label="性别">
						<template slot-scope="scope">
							<span>{{scope.row.volunteer.gender == true?"男":"女"}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="studentId" label="学号">
						<template slot-scope="scope">
							<span>{{scope.row.volunteer.studentId}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="collegeName" label="学院班级">
						<template slot-scope="scope">
							<span>{{scope.row.volunteer.collegeName}}{{scope.row.volunteer.faculty}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="workingHours" label="工时数">
						<template slot-scope="scope">
							<span>{{scope.row.workingHours}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="activityId" label="活动ID">
						<template slot-scope="scope">
							<div @click="toActivityDetail(scope.row.activityId)" class="table-action">
								<span class="table-action-href">{{scope.row.activityId}}</span>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="activityName" label="活动名称">
						<template slot-scope="scope">
							<span>{{scope.row.activity.activityName}}</span>
						</template>
					</el-table-column>
					<el-table-column width="200px" prop="activityTime" label="活动时间">
						<template slot-scope="scope">
							<span>{{dateFormat(scope.row.activity.starTime)}} 至 {{dateFormat(scope.row.activity.endTime)}}</span>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		detailStudentManhour,
		detailVolunteer,
	} from "@/api/manhour";
	export default {
		data() {
			return {
				studentManHourDetail: {},
				tableData: [],
				manhourId: undefined,
			};
		},
		mounted() {
			this.manhourId = this.$route.query.manhourId
			this.getStudentManhourDetail()
			this.getVolunteerDetail()
		},
		beforeRouteLeave(to,from,next){
			to.meta.isAlive = true
			next()
		},
		methods: {
			dateFormat(intDate) {
				if (intDate) {
					return intDate.replace(" 00:00:00", "")
				} else {
					return ""
				}
			},
			getVolunteerDetail() {
				let params = {
					id: this.manhourId
				}
				detailVolunteer(params).then((res) => {
					if (res.code == 0) {
						this.studentManHourDetail = res.data
					}
				})
			},
			getStudentManhourDetail() {
				let params = {
					id: this.manhourId
				}
				detailStudentManhour(this.manhourId, params).then((res) => {
					if (res.code == 0) {
						this.tableData = res.data
					}
				})
			},
			toActivityDetail(id) {
				// let routeEdit = this.$router.resolve({
				// 	path:"/activityList/detail",
				// 	query:{activityId:id}
				// })
				// window.open(routeEdit.href,"_blank")
				this.$router.push({
					path: `/activityList/detail?activityId=${id}`
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	/deep/.el-input__inner {
		height: 36px;
		border-radius: 0px 2px 2px 0px;
		border: 0;
	}

	.studentManHourDetail {
		.studentManHourDetail-content {
			padding: 0 20px 20px 20px;

			.studentManHourDetail-title {
				font-size: 16px;
				font-family: PingFang SC;
				font-weight: bold;
				line-height: 22px;
				color: #1C2233;
				margin: 20px 0 10px 0;
			}

			.table-action-href {
				font-size: 14px;
				font-family: PingFang SC;
				font-weight: 400;
				line-height: 20px;
				color: #1CABB9;
				text-decoration: underline;
			}
		}
	}
</style>
